import navigateToCallback from './../navigation';
import { WWPASS_OK_MSG, WWPASS_STATUS } from '../passkey/constants';

const connectionPool = [];
const closeConnectionPool = () => {
  while (connectionPool.length) {
    const connection = connectionPool.shift();
    if (connection.readyState === WebSocket.OPEN) {
      connection.close();
    }
  }
};

const applyDefaults = (initialOptions) => {
  const defaultOptions = {
    ticket: undefined,
    callbackURL: undefined,
    returnErrors: false,
    log: () => {},
    development: false,
    spfewsAddress: 'wss://spfews.wwpass.com',
    echo: undefined
  };
  return Object.assign({}, defaultOptions, initialOptions);
};
/**
* WWPass SPFE WebSocket connection
* @param {object} options
*
* options = {
*   'ticket': undefined, // stirng
*   'callbackURL': undefined, //string
*   'development': false || 'string' , // work with another spfews.wwpass.* server
*   'log': function (message) || console.log, // another log handler
*   'echo': undefined
* }
*/
const getWebSocketResult = initialOptions => new Promise((resolve, reject) => {
  const options = applyDefaults(initialOptions);
  let clientKey = null;

  const settle = (status, reason) => {
    if (status === 200) {
      resolve({
        ppx: options.ppx,
        version: options.version,
        status,
        reason: WWPASS_OK_MSG,
        ticket: options.ticket,
        callbackURL: options.callbackURL,
        clientKey
      });
    } else {
      reject({
        ppx: options.ppx,
        version: options.version,
        status,
        reason,
        ticket: options.ticket,
        callbackURL: options.callbackURL
      });
    }
  };

  if (!('WebSocket' in window)) {
    settle(WWPASS_STATUS.INTERNAL_ERROR, 'WebSocket is not supported.');
    return;
  }

  const websocketurl = options.spfewsAddress;
  const socket = new WebSocket(websocketurl);
  connectionPool.push(socket);
  const log = options.log;
  try {
    socket.onopen = () => {
      log(`Connected: ${websocketurl}`);
      const deb = {};
      if (window.location && window.location.href) {
        deb.href = window.location.href;
      }
      if (options.debug_lib_version) {
        deb.version = options.debug_lib_version;
      }
      if (options.uniq) {
        deb.uniq = options.uniq;
      }
      if (options.req_counter) {
        deb.req_counter = options.req_counter;
      }
      const message = JSON.stringify({ ticket: options.ticket, debug: deb });
      log(`Sent message to server: ${message}`);
      socket.send(message);
    };

    socket.onclose = () => {
      const index = connectionPool.indexOf(socket);
      if (index !== -1) {
        connectionPool.splice(index, 1);
      }
      log('Disconnected');
      resolve({ refresh: true });
    };

    socket.onmessage = (message) => {
      log(`Message received from server: ${message.data}`);
      const response = JSON.parse(message.data);
      let status = response.code;
      const reason = response.reason;

      if ('clientKey' in response && !clientKey) {
        clientKey = response.clientKey;
      }

      if (response.clientKey) {
        // This is workaround for SPFE not sending status on tickets from putTicket
        status = options.clientKeyOnly ? 200 : 100;
      }

      if (status === 100) { return; }
      settle(status, reason);
      socket.close();
    };
  } catch (e) {
    log(e);
    settle(WWPASS_STATUS.INTERNAL_ERROR, 'WebSocket error');
  }
});

const wwpassWebSocket = (initialOptions) => {
  const options = applyDefaults(initialOptions);
  const log = options.log;
  getWebSocketResult(initialOptions).then((result) => {
    if (result.clientKey && options.catchClientKey) {
      options.catchClientKey(result.clientKey);
    }
    navigateToCallback(result);
  }).catch((err) => {
    if (!err.status) {
      log('WebSocket error', err);
    } else if (err.status === WWPASS_STATUS.INTERNAL_ERROR || options.returnErrors) {
      navigateToCallback(err);
    }
  });
};


export {
  wwpassWebSocket,
  getWebSocketResult,
  closeConnectionPool
};
