import { wwpassQRCodeAuth } from './qrcode/wwpass.qrcode';
import { isClientKeyTicket } from './ticket';
import { WWPASS_STATUS } from './passkey/constants';
import { version } from '../package.json';


const urlAlphabet =
  'ModuleSymbhasOwnPr-0123456789ABCDEFGHNRVfgctiUvz_KqYTJkLxpZXIjQW';

const nanoid = (size = 21) => {
  let id = '';
  let i = size;
  // eslint-disable-next-line no-plusplus
  while (i--) {
    id += urlAlphabet[Math.floor(Math.random() * 64)];
  }
  return id;
};

const uniq = nanoid(10);

const init = (initialOptions) => {
  const defaultOptions = {
    ticketURL: '',
    callbackURL: '',
    hw: false,
    ppx: 'wwp_',
    version: 2,
    debug_lib_version: version,
    log: () => {}
  };

  const options = Object.assign({}, defaultOptions, initialOptions);
  options.qrcode = (typeof options.qrcode === 'string') ? document.querySelector(options.qrcode) : options.qrcode;

  const promises = [];
  promises.push(wwpassQRCodeAuth(options));
  return Promise.race(promises);
};

if ('console' in window && window.console.log) {
  window.console.log(`WWPass QR Code library version ${version} /downgraded`);
}

window.WWPass = {
  init,
  STATUS: WWPASS_STATUS,
  isClientKeyTicket
};

window.wwpassQRCodeAuth = (initialOptions) => {
  const options = Object.assign({
    debug_lib_version: version,
    req_counter: 1,
    uniq
  }, initialOptions);
  wwpassQRCodeAuth(options);
};
